import { useCallback } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { RectangleStackIcon } from "@heroicons/react/24/outline";

import { Badge } from "@jurahilfe/shared/components";

import { Chapter } from "@types";

import ProBadge from "./Upgrade/ProBadge";
import Stepper from "@components/app/Stepper";
import Skeleton from "@components/shared/Skeleton";
import { getIsProBlocked } from "@utils/getIsProBlocked";
import { useDecksForChapter } from "@hooks/firebase/useDecksForChapter";
import useStore from "@hooks/useStore";

const ChapterProgress = ({
  chapter,
  bordered,
}: {
  chapter: Chapter;
  bordered?: boolean;
}) => {
  const router = useRouter();
  const { user } = useStore((state) => ({
    user: state.user,
  }));
  const [decks, loading] = useDecksForChapter({
    topicId: chapter.topicId,
    chapterId: chapter.id,
    userId: user?.uid,
  });

  const topicBlocked = getIsProBlocked(true, user, chapter.topicKey);

  const handleDeckClick = useCallback(
    (deckId, step) => {
      router.push(
        `/lernen/${chapter.topicId}/${chapter.id}/${deckId}?step=${step}`
      );
    },
    [chapter, router]
  );

  return (
    <div
      className={clsx(
        bordered &&
          "dashboard-box-background dashboard-box-border rounded-md p-2 px-3 md:p-4 xl:p-5",
        "mt-2 flex flex-col gap-2 border first:mt-0 md:mt-8 xl:mt-10"
      )}
    >
      <div className="mr-2 flex items-center justify-between text-base lg:text-lg">
        {loading ? (
          <Skeleton type="chapterTitle" />
        ) : (
          <span className="font-semibold leading-5">{chapter.title}</span>
        )}
        {topicBlocked && <ProBadge subscriptionType={chapter.topicKey} />}
      </div>
      <div
        className={clsx(
          bordered ? "" : "lg:ml-4",
          "flex flex-col rounded md:gap-2 lg:gap-2"
        )}
      >
        {loading ? (
          <>
            {[...Array(3)].map((_, i) => (
              <Skeleton type="deckTitle" key={i} />
            ))}
          </>
        ) : (
          decks?.map((deck) => (
            <div
              key={deck.id}
              className={clsx(
                "gap-2 md:ml-4",
                "grid grid-cols-1 items-center md:grid-cols-2"
              )}
              // className="cursor-pointer gap-2 rounded py-1 px-1 hover:bg-slate-200 dark:hover:bg-slate-700"
            >
              <div className="mt-3 flex items-center justify-between gap-2 md:mt-0">
                <div className="text-sm font-semibold sm:text-base sm:font-normal">
                  <RectangleStackIcon className="-mt-0.5 mr-2 inline-block h-5 w-5 text-slate-600 dark:text-slate-400" />
                  {deck.title}
                </div>
                {getIsProBlocked(!deck.isTrial, user, chapter.topicKey) ? (
                  <button onClick={() => router.push("/upgrade")}>
                    <ProBadge
                      subscriptionType={chapter.topicKey}
                      type="minimal"
                    />
                  </button>
                ) : (
                  topicBlocked && deck.isTrial && <Badge>Kostenlos</Badge>
                )}
              </div>
              <div className="-mx-2 md:mx-0 md:mb-0">
                {/* Optional: Show overall progress for that deck */}
                <Stepper
                  proContent={!deck.isTrial}
                  progress={deck.progress}
                  grade={deck.grade}
                  lastTestDate={deck.lastTest}
                  activeStep={0}
                  handleStepClick={(step: number) => {
                    handleDeckClick(deck.id, step);
                  }}
                  size="slim"
                  weakenLastStep
                  highlightNextStep
                  topicKey={chapter.topicKey}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ChapterProgress;
