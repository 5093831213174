import React, { useState, useMemo } from "react";
import clsx from "clsx";
import { useRouter } from "next/router";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

import { Button, Annotation, Badge } from "@jurahilfe/shared/components";
import { TopicTitle } from "@jurahilfe/shared/types";

import { stepConfig } from "../stepConfig";

import ContentDrawer from "@components/shared/ContentDrawer";
import TopicProgress from "@components/app/TopicProgress";

import DashboardCard from "../DashboardCard";

import ProgressBar from "../Stepper/ProgressBar";

import useStore from "@hooks/useStore";
import { calcuateProgressAcrossSteps } from "@utils/calculateProgressAcrossSteps";
import { getIsProBlocked } from "@utils/getIsProBlocked";
import ProBadge from "../Upgrade/ProBadge";
import UpgradeButton from "../Upgrade/UpgradeButton";
import Skeleton from "@components/shared/Skeleton";

/**
 * Card for a topic with progress bars for each step
 * @param title - Title of the topic (as used in database)
 * @param isPreview - If the topic is in preview mode. Will show a preview badge if completeProBlock is not set and it's pro blocked
 * @param completeProBlock - Set true if the topic card should be show as completely pro blocked (e.g. no preview decks available). Will show a pro badge if the user is not pro
 */
const TopicCard = ({
  title,
  isPreview,
  completeProBlock,
}: {
  title: TopicTitle;
  isPreview?: boolean;
  // If the ProBlock should be shown as a complete block (e.g. no preview decks available)
  completeProBlock?: boolean;
}) => {
  const router = useRouter();

  const { topic, user, topicsLoading } = useStore((state) => ({
    topic: state.topics[`${title}`],
    user: state.user,
    topicsLoading: state.topicsLoading,
  }));

  const [linkLoading, setLinkLoading] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const isProBlocked = useMemo(
    () => getIsProBlocked(true, user, topic?.topicKey),
    [topic, user]
  );

  const fullLoading = useMemo(
    () => topicsLoading && !topic,
    [topicsLoading, topic]
  );

  const firstDeckPath = useMemo(() => {
    const firstChapter = topic?.chapters?.[0];
    const firstDeck = firstChapter?.decks?.[0];

    return {
      topicId: topic?.id,
      chapterId: firstChapter?.id,
      deckId: firstDeck?.id,
    };
  }, [topic]);

  const overallProgress = useMemo(
    () => calcuateProgressAcrossSteps(topic?.userProgress?.progress),
    [topic]
  );

  if (!topic)
    return <Skeleton type="custom" width="w-full" height="h-40 sm:h-52" />;

  return (
    <div className="">
      <DashboardCard
        header={{
          title,
          tooltip: (
            <div className="my-1 space-y-1 text-sm font-normal">
              <div>
                Trainiere {topic?.title} mit {topic?.counts?.cards}{" "}
                Lernelementen und Karteikarten
                {topic?.counts?.questions > 0 && (
                  <> und {topic?.counts?.questions} Übungsfällen</>
                )}
                .
              </div>
              <div>
                <ArrowRightCircleIcon className="-mt-0.5 inline-block h-4 w-4 text-orange-500" />{" "}
                Klicke auf &quot;Lernen starten&quot;
              </div>
            </div>
          ),
          extra: (
            <div>
              {isProBlocked ? (
                <ProBadge subscriptionType={topic.topicKey} />
              ) : isPreview ? (
                <Annotation content="Vorübergehend kostenlos als Beta Test verfügbar, während wir die Inhalte laufend erweitern. Wir sind auf euer Feedback gespannt!">
                  <Badge>Kostenloser Beta Test</Badge>
                </Annotation>
              ) : (
                <div className="text-sm font-semibold text-gray-600 dark:text-gray-400">
                  {overallProgress}%
                </div>
              )}
            </div>
          ),
        }}
        loading={fullLoading}
        footer={
          <>
            {isProBlocked && completeProBlock ? (
              <UpgradeButton
                className="flex-1"
                shortened
                topicKey={topic?.topicKey}
              />
            ) : (
              <Button
                size="small"
                type="tertiary"
                width="full"
                className="flex-1"
                onClick={() => {
                  setLinkLoading(true);
                  router
                    .push(
                      `/nachschlagen/${firstDeckPath.topicId}/${firstDeckPath.chapterId}/${firstDeckPath.deckId}`
                    )
                    .then(() => {
                      setLinkLoading(false);
                    });
                }}
                disabled={fullLoading}
                loading={linkLoading}
              >
                Nachschlagen
              </Button>
            )}
            <Button
              size="small"
              type={isProBlocked && completeProBlock ? "tertiary" : "primary"}
              width="full"
              className="flex-1"
              onClick={() => {
                setOpenDrawer(true);
              }}
              disabled={fullLoading}
            >
              {isProBlocked && completeProBlock
                ? "Inhaltsvorschau"
                : "Lernen starten"}
            </Button>
          </>
        }
      >
        <>
          <div className="mx-2 mb-2 text-sm font-medium">
            Gesamtfortschritt nach Lernstufen
          </div>
          <div
            className={clsx(
              topicsLoading && "pointer-events-none animate-pulse",
              fullLoading && "opacity-50",
              "mb-1 mt-2 flex flex-col gap-2"
            )}
          >
            {Object.entries(
              topic?.userProgress?.progress || {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
              }
            ).map(([step, stepProgress]) => {
              if (step === "4") return null;
              return (
                <Annotation
                  className={clsx("w-full select-none rounded-md p-2")}
                  key={step}
                  content={
                    <div className="text-sm">
                      <div className="text-base">
                        <span className="text">Stufe {step}</span>{" "}
                        <span className="font-base min-w-max whitespace-nowrap font-semibold text-orange-500">
                          {`${stepConfig[parseInt(step)].title}`}
                        </span>
                      </div>
                      <div className="text-slate-800 dark:text-slate-200">
                        {stepProgress === 0 ? (
                          <span className="italic text-slate-700 dark:text-slate-300">
                            Beschäftige dich mit den Lerninhalten und schau
                            später wieder vorbei, um hier deinen Fortschritt zu
                            sehen.
                          </span>
                        ) : stepProgress === 1 ? (
                          <span className="text-sm">
                            Glückwunsch! Du hast dich mit allen Lerninhalten
                            schoneinmal befasst - aber Vergiss nicht,
                            Wiederholung ist der Schlüssel zum Erfolg!
                          </span>
                        ) : (
                          <span className="text-sm">{`${Math.round(
                            stepProgress * 100
                          )}% Lernfortschritt entsprechen etwa ${
                            step === "1"
                              ? `${Math.round(
                                  topic?.counts?.cards * stepProgress
                                )} der ${topic?.counts?.cards} Lernelemente.`
                              : step === "2"
                              ? `${Math.round(
                                  topic?.counts?.cards * stepProgress
                                )} der ${topic?.counts?.cards} Karteikarten.`
                              : step === "3"
                              ? `${Math.round(
                                  topic?.counts?.questions * stepProgress
                                )} der ${
                                  topic?.counts?.questions
                                } Wiederholungsfragen.`
                              : ""
                          } 
                      `}</span>
                        )}
                      </div>
                    </div>
                  }
                  placement="top-start"
                >
                  <div className="flex flex-grow items-center">
                    <div className="mr-2">
                      <div className="h-5 w-5  text-slate-500 dark:text-slate-300">
                        {React.createElement(stepConfig[parseInt(step)].icon)}
                      </div>
                    </div>
                    <div className="w-full ">
                      <ProgressBar progress={stepProgress} size="extraThick" />
                    </div>

                    <div className="w-14 text-right text-sm font-semibold text-gray-600 dark:text-gray-400">
                      {Math.round(stepProgress * 100)}%
                    </div>
                  </div>
                </Annotation>
              );
            })}
          </div>
        </>
      </DashboardCard>

      <ContentDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        title={isProBlocked ? `${title} (Vorschau)` : title}
        subtitle={
          isProBlocked && completeProBlock
            ? "Vorschau über Kapitel und Lernpfade"
            : "Wähle einen Lernpfad, um zu beginnen"
        }
      >
        <TopicProgress topicTitle={title} />
      </ContentDrawer>
    </div>
  );
};

export default TopicCard;

// Let's only use the step icon, not the progressIcon component
const StepIcon = ({ step }: { step: number }) => {
  return (
    <div className="h-6 w-6 rounded-full bg-orange-500 p-1 text-orange-50">
      {React.createElement(stepConfig[step].icon)}
    </div>
  );
};
