import React, { useEffect, useState } from "react";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import {
  setDoc,
  arrayUnion,
  serverTimestamp,
  doc,
  getDoc,
  arrayRemove,
} from "firebase/firestore";
import toast from "react-hot-toast";

import { Button, Spinner } from "@jurahilfe/shared/components";

import useStore from "@hooks/useStore";
import { firestore } from "@utils/firebase/firebase-init";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/outline";

const ReminderButton = ({ reminderTitle, children }) => {
  const { email } = useStore((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [hasContentReminder, setHasContentReminder] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (!email) {
      setLoading(false);
      return;
    }
    getDoc(doc(firestore, "contentReminder", email))
      .then((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          console.log("Data: ", data);
          data.topicReminder?.includes(reminderTitle)
            ? setHasContentReminder(true)
            : setHasContentReminder(false);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .finally(() => setLoading(false));
  }, [email, reminderTitle]);

  const addReminder = async (e) => {
    e.preventDefault();
    try {
      // Create a remidner id of the email and random 4 charakters
      await setDoc(
        doc(firestore, "contentReminder", email),
        {
          email: email,
          topicReminder: arrayUnion(reminderTitle),
          reminderLog: arrayUnion({
            reminderTitle,
            action: "addTopicReminder",
            date: new Date().toISOString(),
          }),
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
      console.log("Reminder set!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const removeReminder = async (e) => {
    e.preventDefault();
    try {
      // Create a remidner id of the email and random 4 charakters
      await setDoc(
        doc(firestore, "contentReminder", email),
        {
          email: email,
          topicReminder: arrayRemove(reminderTitle),
          reminderLog: arrayUnion({
            reminderTitle,
            action: "removeTopicReminder",
            date: new Date().toISOString(),
          }),
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
      console.log("Reminder set!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex w-full justify-center">
          <Spinner className={"h-6 w-6"} />
        </div>
      ) : (
        <>
          {hasContentReminder ? (
            <div className="flex flex-col gap-3 text-center">
              <div className="font-sm whitespace-nowrap text-sm font-semibold text-lime-500">
                <CheckBadgeIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle text-lime-500" />
                Benachrichtigung aktiviert
              </div>
              <Button
                size="small"
                width="auto"
                type="text"
                className="whitespace-nowrap"
                Icon={XCircleIcon}
                onClick={(e) => {
                  removeReminder(e)
                    .then(() => {
                      toast(
                        `Wir werden dich nicht mehr an das Erscheinen des Inhalts erinnern.`,
                        { duration: 5000 }
                      );
                      setHasContentReminder(false);
                    })
                    .catch((error) => {
                      console.error("Error adding document: ", error);
                      toast.error(
                        `Fehler beim Entfernen der Erinnerung. Bitte versuche es später erneut.`,
                        { duration: 5000 }
                      );
                    });
                }}
              >
                Abbestellen
              </Button>
            </div>
          ) : (
            <Button
              width="auto"
              size="small"
              disabled={!email}
              type={"tertiary"}
              className="whitespace-nowrap"
              Icon={hasContentReminder ? CheckBadgeIcon : BellAlertIcon}
              onClick={(e) => {
                addReminder(e)
                  .then(() => {
                    toast.success(
                      `Wir werden dich benachrichtigen, sobald die Inhalte verfügbar sind.`,
                      { duration: 5000 }
                    );
                    setHasContentReminder(true);
                  })
                  .catch((error) => {
                    console.error("Error adding document: ", error);
                    toast.error(
                      `Fehler beim Setzen der Erinnerung. Bitte versuche es später erneut.`,
                      { duration: 5000 }
                    );
                  });
              }}
            >
              {children}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default ReminderButton;
