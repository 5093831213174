import React, { useState, useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { CheckIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import Image from "next/image";

import { Button, Modal } from "@jurahilfe/shared/components";
import { useTrack } from "@jurahilfe/shared/hooks";
import { tiers } from "@jurahilfe/shared/config";

import useStore from "@hooks/useStore";

import { useStudySessionCount } from "@hooks/firebase/useStudySessionCount";
import { startStripeSubscribePage } from "@utils/startStripeSubscribePage";
import { updateUserSettings } from "@utils/firebase/updateUserSettings";

const BulletPoint = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx("flex items-center justify-start text-left", className)}
    >
      <CheckIcon className="mr-2 h-4 w-4 flex-shrink-0 text-orange-500" />
      <span className="text-xs font-medium">{children}</span>
    </div>
  );
};

export const getPriceFromTier = ({
  tierId,
  interval,
  env = process.env.NODE_ENV as "development" | "production",
}: {
  tierId: string;
  interval: "monthly" | "annually";
  env?: "development" | "production";
}): string | null => {
  const tier = tiers.find((t) => t.id === tierId);

  if (!tier?.price?.[interval]) {
    return null;
  }

  return env === "development"
    ? tier.price[interval].stripePriceIdDev
    : tier.price[interval].stripePriceId;
};

const DiscountModal = () => {
  const router = useRouter();
  const track = useTrack();

  const { user } = useStore((state) => ({ user: state.user }));
  const [sessionCount] = useStudySessionCount(user?.uid);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const annualPriceId = getPriceFromTier({
    tierId: "tier-pro-plus",
    interval: "annually",
  });

  const couponId =
    process.env.NODE_ENV === "development" ? "QtsGj1ki" : "TP9TAe9Z";

  useEffect(() => {
    if (
      user &&
      sessionCount !== null &&
      sessionCount >= 6 &&
      user.subscriptionStatus &&
      user.subscriptionStatus === "free" &&
      !user.settings?.onboarding?.discountOfferSeen
    ) {
      setShowModal(true);
      track("DiscountOfferSeen");
    }
  }, [user, sessionCount, router.pathname]);

  const handleDismiss = useCallback(() => {
    if (user) {
      // Update the onboarding document
      updateUserSettings(user.uid, "onboarding", {
        discountOfferSeen: true,
      });
      track("DiscountOfferDismissed");
    }
    setShowModal(false);
  }, [user]);

  const handleSubscribe = useCallback(async () => {
    try {
      await startStripeSubscribePage(
        user,
        annualPriceId,
        router,
        setLoading,
        toast,
        couponId
      );
      track("DiscountOfferAccepted");
    } catch (error) {
      console.error("Error starting subscription:", error);
      setLoading(false);
    }
  }, [user, router, annualPriceId, couponId]);

  const content = {
    1: {
      title: "Ein Geschenk wartet auf dich!",
      content: (
        <div className="flex flex-col items-center text-center">
          <Image
            src="/images/gift_closed.png"
            alt="Gift"
            width={350}
            height={350}
          />
        </div>
      ),
      buttonText: "Geschenk öffnen",
    },
    2: {
      title: "Einmaliges Angebot, nur jetzt gültig!",
      content: (
        <div className="flex flex-col items-center">
          <Image
            src="/images/gift_open.png"
            alt="Gift"
            width={230}
            height={230}
          />
          <div className="flex flex-col items-center text-center">
            <div className="mb-4 flex items-center">
              <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">
                Wir schenken dir 25% Rabatt auf das Jahresabo Pro Plus
              </h3>
            </div>
            <p className="mb-7 text-gray-700 dark:text-gray-300">
              Ein Jahr Pro Plus für nur{" "}
              <span className="font-bold">148,50€</span> - Spare über 90€ im
              ersten Jahr!
            </p>
            <div className="grid grid-cols-2 gap-3">
              <BulletPoint className="col-span-2">
                Alle Funktionen + Inhalte im Zivil-, Straf- und ÖffR (sobald
                verfügbar) + JuraSense Chrome Extension + Bonusinhalte
              </BulletPoint>
              <BulletPoint className="col-span-2">
                2 Monate gratis durch Jahresabo + 25% Extra-Rabatt
              </BulletPoint>
            </div>
          </div>
        </div>
      ),
      buttonText: user?.subscription?.trialEligible
        ? "14 Tage risikofrei kostenlos testen"
        : "Jetzt Jahresabo starten und 25% sparen",
    },
  };

  return (
    <Modal
      open={showModal}
      setClose={handleDismiss}
      title={content[step].title}
      type="upgrade"
      size="small"
      closeOnOutsideClick={false}
      showCloseButton={step === 2}
      customFooter={
        <div className="flex w-full flex-col gap-3">
          <Button
            type="upgrade"
            onClick={step === 1 ? () => setStep(2) : handleSubscribe}
            loading={loading}
          >
            {content[step].buttonText}
          </Button>
          {step === 2 && (
            <button
              onClick={handleDismiss}
              className="text-sm text-gray-500 hover:text-gray-700"
            >
              Schließen und einmaliges Angebot ablehnen
            </button>
          )}
        </div>
      }
    >
      {content[step].content}
    </Modal>
  );
};

export default DiscountModal;
