import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

import { Guide, getGuides } from "@utils/firebase/getGuides";

export const useGuides = (): [guides: Guide[], isLoading: boolean] => {
  const [guides, setGuides] = useState<Guide[]>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Get the ratgeber data from the firestore "guides" collection
  useEffect(() => {
    setIsLoading(true);
    getGuides()
      .then((guides) => {
        setGuides(guides);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  }, []);

  return [guides, isLoading];
};
