import React, { useState, useEffect } from "react";

const messages = [
  "Bereit für einen neuen Tag? ☀️",
  "Starte motiviert in den Tag! 💪",
  "Willkommen zurück, bereit für neue Herausforderungen? 👋",
  "Bereit für einen produktiven Tag. ☕️",
  "Lass uns loslegen! 😎",
  "Und wieder ein neuer Tag - was möchtest du erreichen? 🎯",
  "Schön, dass du da bist! Was steht heute auf deinem Plan? 📝",
  "Ein neuer Tag, eine neue Chance! 💡",
  "Time to shine! ✨",
  "Bereit, dein Wissen zu erweitern? 🤓",
  "Auf geht's, Jura wartet auf dich! 📚",
  "Starte deinen Tag mit einem Lächeln! 😊",
  "Ein neuer Tag voller Möglichkeiten. Nutze ihn! 🚀",
  "Herzlich willkommen! Auf was freust du dich heute am meisten? 🤩",
  "Heute ist ein guter Tag zum durchstarten! 🚀",
  "Schön, dass du hier bist! Was möchtest du heute lernen? 🤓",
  "Auf geht's in einen weiteren spannenden Lerntag! 📚",
  "Neuer Tag, neue Erfolge! Bist du bereit? 🌟",
  "Heute ist ein perfekter Tag, um Juristisches zu meistern! 💼",
  "Willkommen zurück! Tauchen wir ein in die Welt des Rechts! ⚖️",
  "Bereit, die Jurawelt zu erobern? Auf geht’s! 🌍",
  "Ein frischer Start in den Tag mit frischen juristischen Perspektiven! 🌅",
  "Willkommen! Heute könnte dein Tag für juristische Durchbrüche sein! 💥",
  "Packen wir’s an – ein Tag voller juristischer Entdeckungen wartet! 🔍",
  "Willkommen! Bereit für spannende Rechtsfälle? 📖",
  "Ein neuer Lerntag beginnt – lass uns gemeinsam juristische Höhen erklimmen! 🏔️",
  "Heute ist ein neuer Tag, um deine Ziele zu erreichen! 🏆",
  "Lass uns gemeinsam die juristischen Herausforderungen meistern! 🧠",
  "Ein weiterer Tag, um dein Wissen zu vertiefen! 📖",
  "Bereit, neue juristische Erkenntnisse zu gewinnen? 🕵️‍♂️",
  "Heute ist ein großartiger Tag, um Fortschritte zu machen! 📈",
  "Willkommen! Lass uns die juristischen Abenteuer beginnen! 🗺️",
  "Ein neuer Tag, um deine juristischen Fähigkeiten zu verbessern! 🛠️",
  "Auf geht's, die Welt des Rechts wartet auf dich! 🌍",
  "Bereit, deine juristischen Träume zu verwirklichen? 🌟",
  "Heute ist ein Tag voller neuer Möglichkeiten und Herausforderungen! 🚀",
  "Willkommen zurück! Lass uns gemeinsam die juristischen Höhen erklimmen! 🏔️",
  "Ein neuer Tag, um deine juristischen Ziele zu erreichen! 🎯",
  "Bereit, die juristischen Geheimnisse zu entdecken? 🔍",
  "Heute ist ein perfekter Tag, um dein juristisches Wissen zu erweitern! 📚",
  "Bereit, juristische Knoten zu entwirren? Let's go! 🧵",
  "Heute ist der perfekte Tag, um dein Rechtsverständnis zu schärfen! ✏️",
  "Willkommen zurück, Jurastudent:in extraordinaire! 🦸‍♂️🦸‍♀️",
  "Lass uns heute das Recht auf unsere Seite bringen! ⚖️😉",
  "Neuer Tag, neue Gesetze zu entdecken! 😉📜🔍",
  "Heute servieren wir: Frisches Wisse mit einer Prise Motivation! 👨‍🍳👩‍🍳",
  "Bereit für eine Reise durch die Welt der Jurisprudenz? 🧳⚖️",
  "Dein tägliche Dosis Jura steht bereit! Nebenwirkung: Mehr Wissen! 💊📚",
];

function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const DailyMessage = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const today = new Date().toDateString();
    const messageIndex = Math.abs(hashCode(today)) % messages.length;
    setMessage(messages[messageIndex]);
  }, []);

  return <div className="text-sm leading-5 md:text-base">{message}</div>;
};

export default DailyMessage;
