import { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Button } from "@jurahilfe/shared/components";

const ContentDrawer = ({
  open,
  onClose,
  children,
  title,
  subtitle,
}: {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}) => {
  const [openDrawer, setOpenDrawer] = useState(open);

  useEffect(() => {
    setOpenDrawer(open);
  }, [open]);

  const handleClose = () => {
    setOpenDrawer(false);
    onClose && onClose();
  };

  return (
    <Drawer
      open={openDrawer}
      direction="bottom"
      onClose={handleClose}
      size={"88%"}
      lockBackgroundScroll={true}
      duration={200}
      style={{
        background: "darkgray",
      }}
      overlayClassName="dark:!bg-gray-800 bg-gray-100/50"
    >
      <div className="relative h-full overflow-y-auto bg-slate-50 pb-10 dark:bg-gray-900">
        <div
          onClick={handleClose}
          className="sticky top-0 z-10 mb-2 w-full border-b border-t pb-5 pt-5 text-slate-900 backdrop-blur-sm dark:border-slate-700/50 dark:bg-gray-900/80 dark:text-white lg:mb-5"
        >
          <div className="mx-auto max-w-7xl px-4 lg:px-4">
            <div className="mb-1 font-medium text-orange-500 sm:mb-2">
              {title}
            </div>
            <div className="max-w-xs text-base font-semibold sm:text-lg sm:leading-6 md:max-w-none lg:text-xl">
              {subtitle}
            </div>
          </div>
          <div className="absolute right-3 top-4 lg:right-5">
            <Button
              size="small"
              width="auto"
              type="tertiary"
              onClick={handleClose}
            >
              <span className="hidden lg:block">Schließen</span>
              <XMarkIcon className="block h-4 w-4 lg:hidden" />
            </Button>
          </div>
        </div>
        <div className="mx-auto mt-3 max-w-7xl px-4 md:pt-5 lg:px-4">
          {children}
        </div>
      </div>
    </Drawer>
  );
};

export default ContentDrawer;
