import { ArrowRightIcon } from "@heroicons/react/24/outline";
import useStore from "@hooks/useStore";
import { updateUserSettings } from "@utils/firebase/updateUserSettings";
import Link from "next/link";
import { toast } from "react-hot-toast";
import { ToastBody, ToastHeader } from "./Toaster";

const FeedbackToast = ({ toastId }) => {
  const { user } = useStore((state) => ({
    user: state.user,
  }));

  return (
    <div className="my-2">
      <ToastHeader>Wie gefällt dir Jurahilfe.de bisher?</ToastHeader>
      <ToastBody>
        <div className="flex flex-col items-center justify-center">
          <div className="my-1 leading-5">
            Gestalte die Entwicklung aktiv mit! Du hilfst uns sehr, wenn du
            unsere 2-minütige Feedback Umfrage beantwortest.
          </div>
          <div className="mt-2 flex w-full justify-between">
            <Link
              href="https://tally.so/r/mJq9WX"
              target="_blank"
              rel="noopener noreferrer"
              className="whitespace-nowrap rounded border border-orange-500 bg-orange-500 px-2 py-1 font-semibold text-white"
              onClick={() => {
                toast.dismiss(toastId);
                updateUserSettings(user.uid, "feedback", {
                  feedbackGiven: true,
                });
              }}
            >
              <span>Feedback geben</span>
              <ArrowRightIcon className="ml-1 inline-block h-4 w-4" />
            </Link>
            <button
              className="text-gray-600 dark:text-white"
              onClick={() => {
                toast.dismiss(toastId);
                updateUserSettings(user.uid, "feedback", {
                  feedbackDismissed: true,
                });
              }}
            >
              <span className="sr-only">Close</span>
              später
            </button>
          </div>
        </div>
      </ToastBody>
    </div>
  );
};

export default FeedbackToast;
