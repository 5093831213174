import clsx from "clsx";
import React from "react";

const DashboardBox = ({
  children,
  className,
  extra,
  title,
  bordered = true,
}: {
  children: React.ReactNode;
  className?: string;
  extra?: React.ReactNode;
  title?: React.ReactNode;
  bordered?: boolean;
}) => {
  return (
    <div className={clsx("", className)}>
      {title && (
        <div className="flex justify-between">
          <h3 className="mb-4 ml-2 text-base font-semibold leading-6 text-slate-900 dark:text-white">
            {title}
          </h3>
          {extra && <div className="mr-2">{extra}</div>}
        </div>
      )}
      <div
        className={clsx(
          bordered
            ? "dashboard-box-background dashboard-box-border rounded-md sm:rounded-lg"
            : ""
        )}
      >
        <div
          className={clsx(
            bordered ? "flex flex-col px-2 py-3 sm:px-4 sm:py-5 xl:px-6" : ""
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const DashboardBoxHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="px-1 py-2 sm:px-2">
      <h3 className="text-lg leading-6 text-gray-900 dark:text-gray-200">
        {children}
      </h3>
    </div>
  );
};

const DashboardBoxBody = ({
  children,
  bordered = true,
}: {
  children: React.ReactNode;
  bordered?: boolean;
}) => {
  return (
    <div
      className={clsx(
        "h-full text-gray-600 dark:text-gray-400",
        bordered ? "py-2 sm:p-2" : ""
      )}
    >
      {children}
    </div>
  );
};

export default Object.assign(DashboardBox, {
  Header: DashboardBoxHeader,
  Body: DashboardBoxBody,
});
