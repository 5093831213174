import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { Annotation } from "@jurahilfe/shared/components";

const InfoIcon = ({ content }: { content: React.ReactNode }) => {
  return (
    <span className="inline-block font-normal">
      <Annotation content={content}>
        <InformationCircleIcon className="h-4 w-4 text-slate-600 dark:text-slate-400" />
      </Annotation>
    </span>
  );
};

export default InfoIcon;
