import { StudySessionFirebase } from "@utils/firebase/saveStudySession";

export interface GroupedItemsProgress {
  [key: string]: {
    date: string;
    itemsProgress: [number, number, number];
  };
}
export const groupSessionsByDate = (
  data: StudySessionFirebase[]
): GroupedItemsProgress => {
  const result = data.reduce((acc, item) => {
    const date = item.date.slice(0, 10);
    const itemsProgressArray = Object.values(item.itemsProgress);
    if (!acc[date]) {
      acc[date] = {
        date,
        itemsProgress: [0, 0, 0],
      };
    }
    acc[date].itemsProgress = acc[date].itemsProgress.map(
      (value, index) => value + itemsProgressArray[index]
    );
    return acc;
  }, {} as any);
  return result;
};
