import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { YoutubeEmbed, Modal } from "@jurahilfe/shared/components";
import { contentInfo } from "@jurahilfe/shared/config";

import { stepConfig } from "../stepConfig";
import InfoBox from "@components/shared/InfoBox";
import Step from "@components/shared/SimpleStep";
import useStore from "@hooks/useStore";
import { updateUserSettings } from "@utils/firebase/updateUserSettings";

const OnboardingModal = () => {
  const { user, reShowOnboarding, set } = useStore((state) => ({
    user: state.user,
    reShowOnboarding: state.showOnboardingModal,
    set: state.set,
  }));

  // const [showOnboarding, setShowOnboarding] = useState(false);

  // Don't autoshow the onboarding modal
  // useEffect(() => {
  //   if (
  //     user &&
  //     user.settings &&
  //     !user.settings?.onboarding?.onboardingDismissed
  //   ) {
  //     setShowOnboarding(true);
  //   } else {
  //     setShowOnboarding(false);
  //   }
  // }, [user]);

  const handleOnboardingSeen = () => {
    set(() => ({
      showOnboardingModal: false,
    }));
    updateUserSettings(user.uid, "onboarding", {
      onboardingDismissed: true,
    });
  };

  return (
    <Modal
      open={reShowOnboarding} // open={showOnboarding || reShowOnboarding}
      setClose={handleOnboardingSeen}
      title={`Willkommen bei Jurahilfe.de - deiner interaktiven Prüfungsvorbereitung!`}
      size="large"
      confirmButtonText={
        <span className="pre whitespace-nowrap ">{`Verstanden, los geht's!`}</span>
      }
      cancelButtonText=""
      buttonPosition="center"
      closeOnOutsideClick={false}
    >
      <div className="mb-3 mt-5 space-y-2">
        <InfoBox title="Überblick" highlight>
          <YoutubeEmbed className="w-full" embedId="60N8I-Lt5Yc" />
        </InfoBox>
        <InfoBox title="Ziel">
          <div className="underline-strong mb-1 mt-3 text-base">
            Mehr Verständnis und bessere Noten durch eine moderne
            Prüfungsvorbereitung mit Jurahilfe.de - interaktiv, kompakt und
            individuell.
          </div>
          <div className="mt-2">
            <strong>Interaktiv:</strong> Lerne aktiv, statt nur passiv zu
            konsumieren. Unser stufenweises Lernkonzept begleitet dich dabei,
            dir das ganze juristische Wissen zu erarbeiten, einzuprägen und an
            Fallbeispielen zu üben.
          </div>
          <div className="mt-2">
            <strong>Kompakt:</strong> Alles relevante Wissen vom ersten Semester
            bis zu den Examina komprimiert aufbereitet und intuitiv strukturiert
            für ein schnelles Erfassen des Lernstoffs.
          </div>
          <div className="mt-2">
            <strong>Individuell:</strong> Behalte den Überblick über deinen
            Lernfortschritt und mache genau da, wo du aufgehört hast. Im
            persönlichen Einzelunterricht mit einem professionellen Repetitor
            kannst du individuelle Verständnislücken schließen, Wissen vertiefen
            und Klausuren bearbeiten.
          </div>
        </InfoBox>
        <InfoBox title="Entwicklung">
          <div className="underline-strong">
            Wir arbeiten laufend daran Jurahilfe.de zu erweitern und zu
            verbessern. Wir freuen uns jederzeit über dein Feedback per{" "}
            <a
              className="underline"
              href="https://tally.so/r/mJq9WX"
              target={"_blank"}
              rel="noreferrer"
            >
              Feedback-Umfrage
            </a>
            , im Live-Chat oder per{" "}
            <a
              className="underline"
              href="mailto:info@jurahilfe.de?subject=Feedback"
            >
              Mail
            </a>
            ! Dein Feedback hilft uns sehr!
          </div>
        </InfoBox>
        <InfoBox title="Funktionen">
          <div className="mb-2">
            <strong>Dashboard:</strong> Im Dashboard findest du alle Inhalte und
            einen Überblick über deine Aktivität in der App. Du kannst jederzeit
            zu den verschiedenen Stufen der Inhalte springen, um genau dort
            weiterzulernen, wo du aufgehört hast.
          </div>
          <div className=" mb-2">
            <strong>Lernen:</strong> Hier kannst du in vier Stufen die Inhalte
            erarbeiten, wiederholen, üben und testen. Wähle einfach das Modul,
            das dich interessiert und lege los!
            <div className="ml-2 mt-5 space-y-3">
              {Object.entries(stepConfig).map(([key, step]) => (
                <Step
                  key={key}
                  title={
                    <span>
                      Stufe {key}:{" "}
                      <span className="text-orange-500">{step.title}</span>
                    </span>
                  }
                  description={step.introduction.abstract}
                  border={key !== "4"}
                />
              ))}
            </div>
          </div>
        </InfoBox>
        <InfoBox title="Inhalte">
          {contentInfo.map((content) => (
            <div className="mb-2" key={content.name}>
              <strong>{content.name}:</strong> {content.description}
            </div>
          ))}
        </InfoBox>
        <InfoBox size="slim" title="Tipp">
          <div>
            Du kannst diese Anleitung jederzeit wieder über das{" "}
            <InformationCircleIcon className="-mt-0.5 inline-block h-5 w-5" />
            -Icon im Dashboard aufrufen.
          </div>
        </InfoBox>
      </div>
    </Modal>
  );
};

export default OnboardingModal;
