import { doc, setDoc } from "firebase/firestore";
import { firestore } from "@utils/firebase/firebase-init";

import { track } from "@jurahilfe/shared/utils";

export const hideOnboardingSteps = async (userId: string) => {
  const docRef = doc(
    firestore,
    "customers",
    userId,
    "appSettings",
    "onboarding"
  );

  track("Onboarding Steps Hidden");

  await setDoc(docRef, { hideOnboardingSteps: true }, { merge: true });
};
