import { useState, useEffect } from "react";
import { firestore } from "@utils/firebase/firebase-init";
import { collection, query, getCountFromServer } from "firebase/firestore";

/**
 * Counts the number of study sessions a user has completed.
 * Note: Only recounts when the user is id changes or the component is remounted.
 */
export const useStudySessionCount = (userId: string) => {
  const [sessionCount, setSessionCount] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSessionCount = async () => {
      if (!firestore || !userId) {
        setLoading(false);
        return;
      }

      const sessionsRef = collection(
        firestore,
        "userProgress",
        userId,
        "studySessions"
      );
      const q = query(sessionsRef);

      try {
        const snapshot = await getCountFromServer(q);
        setSessionCount(snapshot.data().count);
      } catch (error) {
        console.error("Error fetching study session count:", error);
        setSessionCount(null);
      } finally {
        setLoading(false);
      }
    };

    fetchSessionCount();
  }, [userId]);

  return [sessionCount, loading] as const;
};
