import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { firestore } from "./firebase-init";

export interface GuideFirebase {
  title: string;
  content: string;
  sortOrder: number;
  description: string;
}

export interface Guide extends GuideFirebase {
  id: string;
}

export const getGuides = async (): Promise<Guide[]> => {
  try {
    const q = query(
      collection(firestore, "guides"),
      orderBy("sortOrder", "asc")
    );

    const guidesQuerySnap = await getDocs(q);
    const guides = guidesQuerySnap.docs.map((doc) => {
      const guide = doc.data() as GuideFirebase;
      return {
        ...guide,
        id: doc.id,
      };
    });

    return guides;
  } catch (error) {
    console.error("Error getting guides", error);
    throw new Error("Error getting guides");
  }
};
