import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";

import { ErrorMessage, Loading } from "@types";
import { firestore } from "@utils/firebase/firebase-init";
import { StudySessionFirebase } from "@utils/firebase/saveStudySession";

export const usePastStudySessions = (
  userId: string,
  range = 7
): [StudySessionFirebase[] | null, Loading, ErrorMessage] => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [studySessions, setStudySessions] = useState<StudySessionFirebase[]>(
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    if (!firestore || !userId) return;

    // Get the base deck data
    const sessionsRef = collection(
      firestore,
      "userProgress",
      userId,
      "studySessions"
    );

    const q = query(
      sessionsRef,
      where(
        "createdTimestamp",
        ">=",
        new Date().getTime() - range * 24 * 60 * 60 * 1000
      )
    );

    // Get only once
    getDocs(q)
      .then((querySnapshot) => {
        const sessions = [];
        querySnapshot.forEach((doc) => {
          sessions.push(doc.data());
        });
        setStudySessions(sessions);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, [userId, range]);

  return [studySessions, isLoading, error];
};
