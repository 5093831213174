import React from "react";

import { TopicTitle } from "@jurahilfe/shared/types";

import { useOutlineForTopic } from "@hooks/firebase/useOutlineForTopic";
import ChapterProgress from "./ChapterProgress";
import ReminderBox from "./ReminderBox";

const TopicProgress = ({ topicTitle }: { topicTitle: TopicTitle }) => {
  const [topic] = useOutlineForTopic(topicTitle);

  return (
    <div className="flex flex-col gap-2 text-slate-900 dark:text-slate-200">
      {/* <div className="text pb-1 font-semibold text-slate-600 dark:text-slate-300">
        {topicTitle}
      </div> */}
      {topic?.incomplete && <ReminderBox topicTitle={topicTitle} />}
      {topic ? (
        topic?.chapters.map((chapter) => (
          <ChapterProgress key={chapter.id} chapter={chapter} bordered />
        ))
      ) : (
        <ReminderBox topicTitle={topicTitle} />
      )}
      {topic?.incomplete && (
        <div className="mt-10 w-full text-center font-medium">
          ... weitere Inhalte folgen.
        </div>
      )}
    </div>
  );
};

export default TopicProgress;
