import { ProgressSteps } from "@types";

/**
 * Util function to calculate the overall progress across steps 1, 2, and 3. Step 4 is excluded.
 */
export const calcuateProgressAcrossSteps = (progress: ProgressSteps) => {
  if (!progress) return 0;

  const progressValues =
    Object.values(progress).filter((value, index) => index !== 3) || [];

  const progressSum = progressValues.reduce((acc, curr) => acc + curr, 0);
  const progressAverage = progressSum / progressValues.length;
  const displayProgress = Math.round(progressAverage * 100);
  return displayProgress;
};
