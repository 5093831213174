import React from "react";

import clsx from "clsx";
import InfoIcon from "@components/shared/InfoIcon";

const DashboardCard = ({
  header,
  children,
  loading,
  footer,
  className,
  fullHeight = true,
}: {
  header?: {
    title: React.ReactNode;
    tooltip?: React.ReactNode;
    extra?: React.ReactNode;
  };
  footer?: React.ReactNode;
  children?: React.ReactNode;
  loading: boolean;
  className?: string;
  fullHeight?: boolean;
}) => {
  const { title, tooltip, extra } = header || {};
  return (
    <div
      className={clsx(
        fullHeight ? "h-full" : "h-auto",
        "dashboard-box-background dashboard-box-border relative flex flex-col rounded-md px-1 py-4 md:px-3",
        className
      )}
    >
      {loading && (
        <div className="absolute inset-0 z-10 flex animate-pulse items-center justify-center bg-gray-900/80 backdrop-blur-sm" />
      )}

      <div className="h-full flex-grow">
        {header && (
          <div
            className={clsx(
              "flex items-center justify-between gap-2 px-2",
              !children && "pb-2"
            )}
          >
            <div className="flex items-center gap-2 text-slate-900 dark:text-slate-100">
              <span className="text-base font-semibold sm:text-lg sm:leading-6">
                {title}
              </span>
              {tooltip && <InfoIcon content={tooltip} />}
            </div>
            {extra && <div>{extra}</div>}
          </div>
        )}
        {children && (
          <div className="mt-3 flex flex-grow flex-col">{children}</div>
        )}
      </div>
      {footer && (
        <div className="mt-6 flex flex-wrap items-center justify-between gap-2 px-2 ">
          {footer}
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
