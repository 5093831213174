import React, { FC } from "react";
import { useTheme } from "next-themes";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  Legend,
} from "recharts";
import { useMedia } from "react-use";
import { ChartBarIcon } from "@heroicons/react/24/outline";

import { stepConfig as defaultStepConfig } from "./stepConfig";

const mockData = [
  { date: "2024-01-01", Verstehen: 4, Wiederholen: 3, Testen: 2 },
  { date: "2024-01-02", Verstehen: 3, Wiederholen: 4, Testen: 1 },
  { date: "2024-01-03", Verstehen: 5, Wiederholen: 2, Testen: 3 },
  { date: "2024-01-04", Verstehen: 2, Wiederholen: 5, Testen: 4 },
  { date: "2024-01-05", Verstehen: 4, Wiederholen: 3, Testen: 2 },
  { date: "2024-01-06", Verstehen: 3, Wiederholen: 4, Testen: 3 },
  { date: "2024-01-07", Verstehen: 5, Wiederholen: 3, Testen: 2 },
];

const CustomDot = (props) => {
  const { resolvedTheme } = useTheme();
  const { cx, cy, stroke, value } = props;
  if (value === 0) {
    return null;
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      strokeWidth={3}
      stroke={stroke}
      fill={resolvedTheme === "dark" ? "#1f2937" : "#f1f5f9"}
    />
  );
};

export interface ActivityChartData {
  date: string;
  Verstehen: number;
  Wiederholen: number;
  Testen: number;
}

export type LineChartInterval = "day" | "week" | "month";

export interface ActivityLineChartProps {
  data: ActivityChartData[];
  interval: LineChartInterval;
  showPlaceholder?: boolean;
}

export const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul className="mx-auto flex flex-wrap items-center justify-center gap-2 sm:gap-4">
      {payload.map((entry, index) => {
        const Icon =
          entry.value === "Verstehen"
            ? defaultStepConfig[1].icon
            : entry.value === "Wiederholen"
            ? defaultStepConfig[2].icon
            : defaultStepConfig[3].icon;

        return (
          <li
            className="flex items-center gap-1 text-xs font-semibold sm:gap-2 sm:text-sm"
            key={`item-${index}`}
            style={{ color: entry.color }}
          >
            <Icon className="h-6 w-6 p-1 text-slate-600 dark:text-slate-400" />
            <span>{entry.value}</span>
          </li>
        );
      })}
    </ul>
  );
};

const ActivityLineChart: FC<ActivityLineChartProps> = ({
  data,
  interval,
  showPlaceholder,
}) => {
  const { resolvedTheme } = useTheme();
  const isSM = useMedia("(max-width: 640px)", true);

  const chartData = showPlaceholder ? mockData : data;
  const dateCount = chartData.length;
  const chartInterval = showPlaceholder ? "day" : interval;

  return (
    <div className="relative">
      <ResponsiveContainer width="100%" height={isSM ? 200 : 300}>
        <LineChart
          data={chartData}
          margin={{
            right: isSM ? 10 : 30,
            left: isSM ? 10 : 30,
            top: 10,
            bottom: isSM ? 30 : 10,
          }}
        >
          {/* <CartesianGrid
          vertical={false}
          stroke={resolvedTheme === "dark" ? "#475569" : "#cbd5e1"}
        /> */}
          <Tooltip
            labelClassName="text-sm text-slate-900 dark:text-slate-100"
            wrapperClassName="shadow-xl"
            separator=": "
            formatter={(value, name) => {
              return [
                `${value} ${
                  name === "Verstehen"
                    ? "Blöcke"
                    : name === "Wiederholen"
                    ? "Karten"
                    : name === "Testen"
                    ? "Fragen"
                    : "Elemente"
                }`,
                name,
              ];
            }}
            labelFormatter={(label) => formatLabel(label, chartInterval)}
            wrapperStyle={{
              borderRadius: "5px",
              outline: "none",
              overflow: "hidden",
            }}
            contentStyle={{
              borderRadius: "5px",
              outline: "none",
              overflow: "hidden",
              backgroundColor: resolvedTheme === "dark" ? "#1f2937" : "white",
              border:
                resolvedTheme === "dark"
                  ? "1px solid #475569"
                  : "1px solid #e5e7eb",
              padding: "10px",
            }}
            cursor={{
              stroke: resolvedTheme === "dark" ? "#475569" : "#cbd5e1",
              strokeWidth: 2,
            }}
          />
          <XAxis
            dataKey="date"
            axisLine={{
              stroke: resolvedTheme === "dark" ? "#475569" : "#cbd5e1",
            }}
            interval={
              dateCount <= 13 && chartInterval !== "week"
                ? 0
                : "preserveStartEnd"
            }
            tickMargin={5}
            height={40}
            tickLine={false}
            tickFormatter={(value) => {
              return tickFormatter(value, chartInterval);
            }}
            minTickGap={40}
            tick={{
              fontSize: 13,
              fontWeight: 500,
              fill: resolvedTheme === "dark" ? "#cbd5e1" : "#1f2937",
            }}
            hide={isSM}
          />
          <Line
            type="monotone"
            dot={(props) => <CustomDot {...props} key={props.key} />}
            dataKey="Verstehen"
            stroke={resolvedTheme === "dark" ? "#fb923c" : "#fb923c"}
            strokeWidth={isSM ? 3 : 2}
          />
          <Line
            type="monotone"
            dot={<CustomDot />}
            dataKey="Wiederholen"
            stroke={resolvedTheme === "dark" ? "#f97316" : "#f97316"}
            strokeWidth={isSM ? 3 : 2}
          />
          <Line
            type="monotone"
            dot={<CustomDot />}
            dataKey="Testen"
            stroke={resolvedTheme === "dark" ? "#ea580c" : "#ea580c"}
            strokeWidth={isSM ? 3 : 2}
          />
          <Legend
            wrapperStyle={
              isSM && {
                position: "relative",
                marginTop: "0px",
              }
            }
            content={renderLegend}
          />
        </LineChart>
      </ResponsiveContainer>
      {showPlaceholder && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex w-10/12 flex-col items-center justify-center gap-2 rounded-md bg-white/70 p-4 py-3 text-center backdrop-blur-sm dark:bg-gray-800/70 sm:py-5">
            <ChartBarIcon className="mr-2 h-6 w-6 text-gray-400 dark:text-gray-500" />
            <div>Hier siehst du Statistiken deiner Lernaktivitäten</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityLineChart;

const formatLabel = (label, interval) => {
  // Format the label according to the interval
  if (interval === "day") {
    return new Date(label).toLocaleDateString("de-DE", {
      weekday: "short",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  } else if (interval === "week") {
    return `${new Date(label).toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
    })} bis ${new Date(
      new Date(label).getTime() + 6 * 24 * 60 * 60 * 1000
    ).toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })}`;
  } else if (interval === "month") {
    return new Date(label).toLocaleDateString("de-DE", {
      month: "long",
      year: "numeric",
    });
  }
};

const tickFormatter = (value, interval) => {
  // Format the tick according to the interval
  if (interval === "day") {
    return new Date(value).toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
    });
  } else if (interval === "week") {
    return `${new Date(value).toLocaleDateString("de-DE", {
      day: "numeric",
      month: "2-digit",
    })} - ${new Date(
      new Date(value).getTime() + 6 * 24 * 60 * 60 * 1000
    ).toLocaleDateString("de-DE", {
      day: "numeric",
      month: "2-digit",
    })}`;
  } else if (interval === "month") {
    return new Date(value).toLocaleDateString("de-DE", {
      month: "short",
      year: "2-digit",
    });
  }
};
