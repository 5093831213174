import React, { useEffect, useState } from "react";
import {
  BookOpenIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { toast } from "react-hot-toast";
import { useWindowSize } from "react-use";
import { useRouter } from "next/router";

import { Button, MainLayout, Logo } from "@jurahilfe/shared/components";
import { useTrack } from "@jurahilfe/shared/hooks";

import DashboardBox from "../DashboardBox";
import ActivityLineChart, {
  ActivityChartData,
  LineChartInterval,
} from "../ActivityLineChart";
import OnboardingModal from "./OnboardingModal";
import DailyMessage from "./DailyMessage";
import TopicCard from "./TopicCard";
import DashboardCard from "../DashboardCard";

import ButtonGroup from "@components/shared/ButtonGroup";

import FeedbackToast from "@components/shared/FeedbackToast";
import UpgradeButton from "../Upgrade/UpgradeButton";
import ProBadge from "../Upgrade/ProBadge";
import InfoIcon from "@components/shared/InfoIcon";
import OnboardingSteps from "./OnboardingSteps";
import DiscountModal from "../DiscountModal";

import { usePastStudySessions } from "@hooks/firebase/usePastStudySessions";
import useStore from "@hooks/useStore";
import { useGuides } from "@hooks/firebase/useGuides";
import { useHasStudySessions } from "@hooks/firebase/useHasStudySession";

import { groupSessionsByDate } from "@utils/groupSessionsByDate";
import {
  getAggregationType,
  prepareActivityLineChartData,
} from "@utils/prepareActivityLineChartData";
import { getIsProBlocked } from "@utils/getIsProBlocked";

const showPlacehoders = false;

const StreakIcon = ({ active = true }) => (
  <div
    className={clsx(
      "rounded-full bg-slate-800 p-4 shadow-lg",
      !active && "grayscale"
    )}
  >
    <Logo size="small" />
  </div>
);

function Dashboard() {
  const router = useRouter();
  const windowSize = useWindowSize();
  const track = useTrack();

  const { user, set, initializeTopics } = useStore((state) => ({
    user: state.user,
    set: state.set,
    initializeTopics: state.initializeTopics,
  }));

  const [guides, guidesLoading] = useGuides();
  const guidesProBlocked = getIsProBlocked(true, user, "guides"); // Set to true to make guides pro blocked

  const hasStudySessions = useHasStudySessions(user?.uid);

  useEffect(() => {
    if (user) {
      initializeTopics();
    }
  }, [user]);

  const isSmallScreen = windowSize.width ? windowSize.width < 640 : false; // Get isSmallScreen from windowSize.width

  const [itemsProgressDays, setItemsProgressDays] = useState<
    7 | 30 | 90 | 180 | 365
  >(7);
  const [itemsProgressData] = usePastStudySessions(user.uid, itemsProgressDays);

  const [lineChartInterval, setLineChartInterval] =
    useState<LineChartInterval>("day");

  const [activityLineChartData, setActivityLineChartData] = useState<
    ActivityChartData[]
  >([]);

  useEffect(() => {
    // Show toast with link to feedback form if there are more than three sessions and the user has not yet given feedback
    // Could also use useStudySessionCount, but the itemsProgressData is alreday available anyway
    if (
      itemsProgressData &&
      itemsProgressData.length > 4 &&
      !user.settings?.feedback?.feedbackGiven &&
      !user.settings?.feedback?.feedbackDismissed
    ) {
      toast((t) => <FeedbackToast toastId={t.id} />, { duration: 20000 });
    }
  }, [itemsProgressData, user.settings]);

  useEffect(() => {
    if (itemsProgressData) {
      // Group by date and sum up the itemsProgresses
      const grouped = groupSessionsByDate(itemsProgressData);

      setActivityLineChartData(
        prepareActivityLineChartData(grouped, itemsProgressDays)
      );
      setLineChartInterval(getAggregationType(itemsProgressDays));
    }
  }, [itemsProgressData]);

  return (
    <>
      <MainLayout type="fixedWitdhScroll">
        <div className="mx-auto grid min-h-[calc(100vh-4rem)] grid-cols-6 gap-x-4 gap-y-10 px-3 pb-10 sm:gap-8 sm:px-2 md:gap-10 md:gap-x-4 md:gap-y-10 md:px-4 xl:px-20">
          <div
            className={clsx(
              "col-span-6 mx-2 flex justify-center gap-2 pt-6 sm:py-4 sm:pt-8"
              // "sticky top-0 bg-slate-50 dark:bg-slate-800"
            )}
          >
            <div className="flex-grow">
              <div className="text-lg md:text-xl">
                {/* TODO: Get user name from Firebase and save to Storage */}
                Hallo, {user?.displayName || user?.claims?.name || user?.email}!
              </div>
              <div className="text-gray-600 dark:text-gray-400">
                <DailyMessage />
              </div>
            </div>
            <div>
              <InfoButton
                onClick={() => {
                  // Show OnboardingModal
                  track("Re-Open Onboarding Modal");
                  set((state) => {
                    state.showOnboardingModal = true;
                  });
                }}
              />
            </div>
          </div>
          {showPlacehoders && (
            <div className="col-span-6 flex h-32 items-center justify-center">
              <div className="flex w-full justify-around gap-4">
                <StreakIcon />
                <StreakIcon />
                <StreakIcon />
                <StreakIcon />
                <StreakIcon />
                <StreakIcon active={false} />
                <StreakIcon active={false} />
                <StreakIcon active={false} />
                <StreakIcon active={false} />
                <StreakIcon active={false} />
              </div>
            </div>
          )}

          {showPlacehoders && (
            <DashboardBox className="col-span-6 shrink-0" title="Dein Lernplan">
              <DashboardBox.Body>
                <div className="h-48"></div>
              </DashboardBox.Body>
            </DashboardBox>
          )}
          <DashboardBox
            className={clsx(
              user?.settings?.onboarding?.hideOnboardingSteps
                ? "md:col-span-6"
                : "md:col-span-4",
              "col-span-6 shrink-0"
            )}
            title="Deine Aktivität"
          >
            <DashboardBox.Header>
              <div className="flex flex-wrap items-center justify-between gap-2">
                {/* <div>Deine Aktivität</div> */}
                {/* TODO: Add Buttongroup */}
                <div className="flex flex-grow justify-end">
                  <ButtonGroup
                    disabled={!hasStudySessions}
                    buttonsData={[
                      {
                        // Show "12 M" on small screens and "12 Monate" on larger screens
                        title: isSmallScreen ? "12 M" : "12 Monate",
                        onClick: () => setItemsProgressDays(365),
                        active: itemsProgressDays === 365,
                      },
                      {
                        title: isSmallScreen ? "6 M" : "6 Monate",
                        onClick: () => setItemsProgressDays(180),
                        active: itemsProgressDays === 180,
                      },
                      {
                        title: isSmallScreen ? "3 M" : "3 Monate",
                        onClick: () => setItemsProgressDays(90),
                        active: itemsProgressDays === 90,
                      },
                      {
                        title: isSmallScreen ? "30 T" : "30 Tage",
                        onClick: () => setItemsProgressDays(30),
                        active: itemsProgressDays === 30,
                      },
                      {
                        title: isSmallScreen ? "7 T" : "7 Tage",
                        onClick: () => setItemsProgressDays(7),
                        active: itemsProgressDays === 7,
                      },
                    ]}
                  />
                </div>
              </div>
            </DashboardBox.Header>
            <DashboardBox.Body>
              <div className="h-48 sm:h-80">
                <ActivityLineChart
                  data={activityLineChartData}
                  interval={lineChartInterval}
                  showPlaceholder={!hasStudySessions}
                />
              </div>
            </DashboardBox.Body>
          </DashboardBox>
          {/* Add a Box with 3 Onboarding Steps */}
          {!user?.settings?.onboarding?.hideOnboardingSteps && (
            <DashboardBox
              className="col-span-6 row-start-2 md:col-span-2 md:row-start-auto"
              title={
                <span className="flex items-center gap-2">
                  <div className="inline-block">
                    <Logo size="xxsmall" />
                  </div>
                  Entdecke Jurahilfe.de
                </span>
              }
              bordered={false}
            >
              <DashboardBox.Body bordered={false}>
                <OnboardingSteps />
              </DashboardBox.Body>
            </DashboardBox>
          )}

          <DashboardBox
            className="col-span-6"
            title="Rechtsgebiete"
            bordered={false}
          >
            <DashboardBox.Body bordered={false}>
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                <TopicCard title="Zivilrecht" />
                <TopicCard title="Strafrecht" />
                <TopicCard title="Öffentliches Recht" isPreview />
              </div>
            </DashboardBox.Body>
          </DashboardBox>

          <DashboardBox
            className="col-span-6"
            title={
              <span className="inline-flex items-center gap-2">
                Bonusinhalte
                {/* <Badge type="neutral">Vorschau</Badge> */}
              </span>
            }
            // extra={guidesProBlocked && <ProBadge subscriptionType="proPlus" />}
            bordered={false}
          >
            <DashboardBox.Body bordered={false}>
              <div className="md grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                <div className="col-span-1 md:col-span-2">
                  <DashboardCard
                    fullHeight={false}
                    header={{
                      title: <span>Ratgeber</span>,
                      extra: guidesProBlocked && (
                        <ProBadge subscriptionType="proPlus" />
                      ),
                    }}
                    loading={guidesLoading}
                  >
                    <div className="mb-1">
                      <div className="mx-2 text-sm font-medium">
                        Unentbehrliche Wegweiser für deinen Erfolg in Hausarbeit
                        und Klausur. Erfahre mit minimalem Aufwand, wie du
                        juristische Arbeiten elegant, präzise und stilsicher
                        gestalten kannst
                      </div>
                      <div className="mt-6 flex h-full flex-col gap-3">
                        {guides?.map((guide) => (
                          <div
                            key={guide.id}
                            className={clsx(
                              "flex flex-row items-center justify-center gap-3 rounded-md p-2 sm:gap-4 md:items-center"
                              // "border border-slate-100 dark:border-slate-700/20"
                            )}
                          >
                            <div className="flex flex-grow items-center dark:text-slate-200">
                              <BookOpenIcon className="-mt-0.5 mr-2 inline-block h-5 w-5 sm:mr-1.5" />
                              <span className="inline-flex items-center justify-between gap-2 text-sm font-semibold text-slate-900 dark:text-white sm:text-base sm:leading-5">
                                {/* Truncate only on small screens */}
                                <span>{guide.title}</span>
                                <InfoIcon content={guide.description} />
                              </span>
                            </div>
                            {guidesProBlocked ? (
                              <>
                                <UpgradeButton
                                  shortened
                                  topicKey="guides"
                                  proPlus={true}
                                />
                              </>
                            ) : (
                              <Button
                                size="small"
                                width="auto"
                                type="tertiary"
                                onClick={() => {
                                  router.push(`/ratgeber/${guide.id}`);
                                }}
                              >
                                Lesen
                              </Button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </DashboardCard>
                </div>
                <div className="col-span-1">
                  <TopicCard
                    title="Juristisches Allgemeinwissen"
                    completeProBlock
                  />
                </div>
              </div>
            </DashboardBox.Body>
          </DashboardBox>

          {/* <NumberDisplay title="Deine Noten" className={"col-span-6"}>
            <NumberDisplay.Item
              name="Durschnittsnote (aktuellste Tests)"
              stat="13 Punkte"
            />
            <NumberDisplay.Item
              name="Korrekte Antworten (aktuellste Tests)"
              stat="56%"
            />
            <NumberDisplay.Item name="Abschlusstests absolviert" stat="3" />
          </NumberDisplay> */}
          {showPlacehoders && (
            <DashboardBox
              className="col-span-6 shrink-0 md:col-span-3"
              title="Deine Noten"
            >
              <DashboardBox.Body>
                <div className="h-64"></div>
              </DashboardBox.Body>
            </DashboardBox>
          )}
          {/* <DashboardBox className="col-span-6 shrink-0 md:col-span-3">
          <DashboardBox.Header>
            <div className="flex flex-wrap items-center justify-between gap-2">
              <div>Deine Gesamtaktivität</div>

              <div className="flex flex-grow justify-end">
                <ButtonGroup
                  buttonsData={[
                    {
                      title: "Letzte 90 Tage",
                      onClick: () => setItemsProgressDays(90),
                      active: itemsProgressDays === 90,
                    },
                    {
                      title: "Letzte 30 Tage",
                      onClick: () => setItemsProgressDays(30),
                      active: itemsProgressDays === 30,
                    },
                    {
                      title: "Letzte 7 Tage",
                      onClick: () => setItemsProgressDays(7),
                      active: itemsProgressDays === 7,
                    },
                  ]}
                />
              </div>
            </div>
          </DashboardBox.Header>
          <DashboardBox.Body>
            <div className="h-full">
              <ActivityBarChart data={activityLineChartData} />
            </div>
          </DashboardBox.Body>
        </DashboardBox> */}

          {/* <DashboardBox className="col-span-6" title="Ratgeber">
            <DashboardBox.Body>
              <div className="grid grid-rows-2 gap-4 md:grid-cols-2 lg:grid-cols-3">
                <div className="flex flex-col gap-4 rounded-md border border-slate-700/50 p-4">
                  <div className="text-lg font-semibold text-slate-200">
                    Ratgeber 1
                  </div>
                  <div className="flex-grow text-sm text-gray-600 dark:text-gray-400">
                    15 Kapitel | 120 Karteikarten | 30 Wiederholungsfragen
                  </div>
                  <Button
                    size="small"
                    type="tertiary"
                    onClick={() => {
                      console.log("Lernen");
                    }}
                  >
                    Lesen
                  </Button>
                </div>
                <div className="flex flex-col gap-4 rounded-md border border-slate-700/50 p-4">
                  <div className="text-lg font-semibold text-slate-200">
                    Ratgeber 1
                  </div>
                  <div className="flex-grow text-sm text-gray-600 dark:text-gray-400">
                    15 Kapitel | 120 Karteikarten | 30 Wiederholungsfragen
                  </div>
                  <Button
                    size="small"
                    type="tertiary"
                    onClick={() => {
                      console.log("Lernen");
                    }}
                  >
                    Lesen
                  </Button>
                </div>
                <div className="flex flex-col gap-4 rounded-md border border-slate-700/50 p-4">
                  <div className="text-lg font-semibold text-slate-200">
                    Ratgeber 1
                  </div>
                  <div className="flex-grow text-sm text-gray-600 dark:text-gray-400">
                    15 Kapitel | 120 Karteikarten | 30 Wiederholungsfragen
                  </div>
                  <Button
                    size="small"
                    type="tertiary"
                    onClick={() => {
                      console.log("Lernen");
                    }}
                  >
                    Lesen
                  </Button>
                </div>

                <TopicCard
                  title="Allgemeinwissen"
                  progress={{
                    1: 60,
                    2: 50,
                    3: 75,
                  }}
                >
                  <ActivityLineChart
                    data={activityLineChartData}
                    interval={lineChartInterval}
                  />
                </TopicCard>
              </div>
            </DashboardBox.Body>
          </DashboardBox> */}

          {/* <DashboardBox className="col-span-6" title="Kapitelfortschritt">
            <DashboardBox.Body>
              <div className="flex flex-col gap-2">
                <BrandTabs growTabs size="sm">
                  <BrandTabs.Tab label="Zivilrecht">
                    <div className="mt-6 xl:mt-8">
                      <TopicProgress topicTitle="Zivilrecht" />
                    </div>
                  </BrandTabs.Tab>
                  <BrandTabs.Tab
                    label={
                      <span className="inline-flex flex-wrap items-center justify-center gap-2 gap-y-1">
                        <span>Strafrecht</span>
                        <Badge type="neutral">Vorschau</Badge>
                      </span>
                    }
                  >
                    <div className="mt-2 xl:mt-4">
                      <TopicProgress topicTitle="Strafrecht" />
                    </div>
                  </BrandTabs.Tab>
                  <BrandTabs.Tab
                    label={
                      <span className="inline-flex flex-wrap items-center justify-center gap-2 gap-y-1">
                        <span>Öffentliches Recht</span>
                        <Badge type="neutral">Vorschau</Badge>
                      </span>
                    }
                  >
                    <div className="mt-2 xl:mt-4">
                      <TopicProgress topicTitle="Öffentliches Recht" />
                    </div>
                  </BrandTabs.Tab>
                </BrandTabs>
              </div>
            </DashboardBox.Body>
          </DashboardBox> */}
        </div>
        <OnboardingModal />
        <DiscountModal />
      </MainLayout>
    </>
  );
}

const InfoButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx(
        "btn-text-default",
        "flex items-center gap-1 rounded-lg px-2 py-1 text-sm text-slate-200"
      )}
    >
      <span className="sr-only">Einführung anzeigen</span>
      <span className="pl-1 font-medium text-orange-600 dark:text-orange-500">
        Tutorial
      </span>
      <InformationCircleIcon
        className="h-6 w-6 text-orange-600 dark:text-orange-500"
        aria-hidden="true"
      />
    </button>
  );
};

export default Dashboard;
