import { LineChartInterval } from "@components/app/ActivityLineChart";
import { GroupedItemsProgress } from "./groupSessionsByDate";

export const prepareActivityLineChartData = (
  data: GroupedItemsProgress,
  periodDays: number
) => {
  const aggregationType = getAggregationType(periodDays);
  const aggregatedData = aggregateData(data, aggregationType);
  const earliestDate = getEarliestDate(data, periodDays);
  const filledData = fillMissingPeriods(
    aggregatedData,
    aggregationType,
    new Date(earliestDate),
    new Date()
  );
  return formatForLineChart(filledData);
};

export const getAggregationType = (periodDays: number): LineChartInterval =>
  periodDays <= 30 ? "day" : periodDays <= 180 ? "week" : "month";

function getEarliestDate(data: GroupedItemsProgress, periodDays: number) {
  // Get the date from the periodDays ago
  const fallbackDate = new Date();
  fallbackDate.setDate(fallbackDate.getDate() - periodDays);
  return fallbackDate;
}

function aggregateData(
  data: GroupedItemsProgress,
  aggregationType: LineChartInterval
) {
  const aggregated = {};

  Object.keys(data).forEach((date) => {
    const key = getAggregationKey(new Date(date), aggregationType);
    if (!aggregated[key]) {
      aggregated[key] = [0, 0, 0];
    }
    data[date].itemsProgress.forEach((value, index) => {
      aggregated[key][index] += value;
    });
  });

  return aggregated;
}

function getAggregationKey(date: Date, aggregationType: LineChartInterval) {
  if (aggregationType === "day") {
    return date.toISOString().split("T")[0];
  } else if (aggregationType === "week") {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay());
    return startOfWeek.toISOString().split("T")[0];
  } else {
    // month
    return date.toISOString().split("T")[0].substring(0, 7);
  }
}

function fillMissingPeriods(
  aggregatedData: GroupedItemsProgress,
  aggregationType: LineChartInterval,
  earliestDate: Date,
  currentDate: Date
): GroupedItemsProgress {
  const filledData = {};
  const date = new Date(earliestDate);

  while (date <= currentDate) {
    const key = getAggregationKey(date, aggregationType);
    if (!filledData[key]) {
      filledData[key] = aggregatedData[key] || [0, 0, 0];
    }
    incrementDate(date, aggregationType);
  }

  return filledData;
}

function incrementDate(date: Date, aggregationType: LineChartInterval) {
  if (aggregationType === "day") {
    date.setDate(date.getDate() + 1);
  } else if (aggregationType === "week") {
    date.setDate(date.getDate() + 7);
  } else {
    date.setMonth(date.getMonth() + 1);
  }
}

function formatForLineChart(filledData: GroupedItemsProgress) {
  return Object.keys(filledData).map((key) => ({
    date: key,
    Verstehen: filledData[key][0],
    Wiederholen: filledData[key][1],
    Testen: filledData[key][2],
  }));
}
