import { useState, useEffect } from "react";
import { firestore } from "@utils/firebase/firebase-init";
import { collection, query, limit, getDocs } from "firebase/firestore";

export const useHasStudySessions = (userId: string) => {
  const [hasStudySessions, setHasStudySessions] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const checkStudySessions = async () => {
      if (!firestore || !userId) return;

      const sessionsRef = collection(
        firestore,
        "userProgress",
        userId,
        "studySessions"
      );
      const q = query(sessionsRef, limit(1));

      try {
        const querySnapshot = await getDocs(q);
        setHasStudySessions(!querySnapshot.empty);
      } catch (error) {
        console.error("Error checking study sessions:", error);
        setHasStudySessions(null);
      }
    };

    checkStudySessions();
  }, [userId]);

  console.log("hasStudySessions", hasStudySessions);

  return hasStudySessions;
};
