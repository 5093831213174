import React from "react";
import { BellAlertIcon } from "@heroicons/react/24/outline";
import MessageBox from "./MessageBox";
import ReminderButton from "./ReminderButton";

const ReminderBox = ({
  topicTitle,
  className,
}: {
  topicTitle: string;
  className?: string;
}) => (
  <MessageBox noMargin className={className}>
    <div className="flex flex-col items-center gap-4 gap-y-5 md:flex-row">
      <div className="space-y-2">
        <span className="mb-4 font-semibold text-slate-900 dark:text-white">
          <span>
            <BellAlertIcon className="mr-2 inline-block h-6 w-6 text-orange-500" />
          </span>
          <span>
            Lass dich benachrichtigen, sobald neue {topicTitle}-Lektionen
            verfügbar sind!
          </span>
        </span>
        <div className="space-y-2 text-sm">
          <p>
            Teste schon jetzt die ersten Lektionen im Strafrecht komplett
            kostenlos, während wir mit Hochdruck an weiteren Inhalten arbeiten.{" "}
            {/* <span className="font-semibold">
              Alles was du brauchst, um Zugriff auf{" "}
              <Badge size="small">Vorschau</Badge>-Inhalte zu erhalten, ist eine
              beliebige <ProBadge />
              -Mitgliedschaft.
            </span>{" "} */}
            {/* Falls du noch neu bei Jurahilfe.de bist, kannst du{" "}
            <Link href={"/upgrade"} target="_blank" className="underline">
              {" "}
              hier deine kostenlose 14-tägige Testphase starten
            </Link>
            . */}
            Beantrage zudem eine Benachrichtigung, um sofort informiert zu
            werden, sobald alle Lektionen und Abo-Angebote verfügbar sind.
          </p>
        </div>
      </div>
      <div className="flex justify-end md:w-96">
        <ReminderButton reminderTitle={topicTitle}>
          Benachrichtige mich
        </ReminderButton>
      </div>
    </div>
  </MessageBox>
);

export default ReminderBox;
