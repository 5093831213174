// OnboardingSteps.tsx
import clsx from "clsx";
import React, { useState, useEffect, useMemo } from "react";
import {
  Disclosure,
  Transition,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronUpIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { OnboardingStepId } from "@types";

import { Button } from "@jurahilfe/shared/components";

import useStore from "@hooks/useStore";
import { hideOnboardingSteps } from "@utils/firebase/hideOnboardingSteps";
import { getOnboardingSteps } from "@utils/getOnboardingSteps";

const OnboardingSteps = () => {
  const onboardingSteps = getOnboardingSteps();

  const { user } = useStore((state) => ({
    user: state.user,
  }));

  const userId = user?.uid;

  const progress = useMemo(
    () => user?.settings?.onboarding?.steps || {},
    [user?.settings?.onboarding?.steps]
  );

  const allStepsDone = useMemo(() => {
    return onboardingSteps.every((step) => progress[step.id]);
  }, [progress]);

  // Find the first incomplete step
  const firstIncompleteStep = useMemo(() => {
    return onboardingSteps.find((step) => !progress[step.id])?.id;
  }, [progress]);

  const [openStep, setOpenStep] = useState<OnboardingStepId | null>(
    firstIncompleteStep || null
  );

  useEffect(() => {
    if (firstIncompleteStep) {
      setOpenStep(firstIncompleteStep);
    }
  }, [firstIncompleteStep]);

  const handleHide = async () => {
    await hideOnboardingSteps(userId);
  };

  return (
    <div>
      <div className="relative w-full">
        <div className="absolute inset-y-full left-6 top-0 z-0 h-full w-0.5 bg-slate-200/80 dark:bg-slate-700/80" />
        <div className="space-y-2">
          {onboardingSteps.map((step) => (
            <Disclosure
              key={step.id}
              as="div"
              defaultOpen={step.id === openStep}
              onChange={() =>
                setOpenStep(openStep === step.id ? null : step.id)
              }
            >
              {({ open }) => (
                <div
                  className={clsx(
                    "relative z-10",
                    open && "rounded-lg dark:bg-slate-800"
                  )}
                >
                  <DisclosureButton
                    className={clsx(
                      "dashboard-box-border relative",
                      "flex w-full items-center justify-between px-2 py-1 text-left text-sm font-medium text-slate-800 dark:text-white md:px-3 md:py-2",
                      "focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75",
                      open ? "rounded-t-lg" : "rounded-lg",
                      "bg-white dark:bg-slate-800"
                    )}
                  >
                    <span
                      className={clsx(
                        progress[step.id]
                          ? "line-through decoration-neutral-700 dark:decoration-slate-300"
                          : "",
                        "flex items-center "
                      )}
                    >
                      <div className="flex h-8 w-8 items-center justify-center">
                        {progress[step.id] ? (
                          <CheckCircleIcon
                            className={clsx(
                              progress[step.id]
                                ? "text-green-500"
                                : "text-gray-500",
                              "mr-2 h-7 w-7"
                            )}
                          />
                        ) : (
                          <div className="mr-2 flex h-4 w-4 items-center justify-center rounded-full bg-slate-200/80 dark:bg-slate-500/70 md:h-5 md:w-5">
                            <div className="h-1.5 w-1.5 rounded-full bg-slate-500/70 dark:bg-slate-800/70" />
                          </div>
                        )}
                      </div>
                      {step.title}
                    </span>
                    <ChevronUpIcon
                      className={clsx(
                        "mr-2 h-5 w-5 transition-transform",
                        open ? "rotate-180 transform" : "",
                        "text-slate-600 dark:text-slate-400"
                      )}
                    />
                  </DisclosureButton>
                  <Transition
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 -translate-y-6"
                    enterTo="opacity-100 translate-y-0"
                    leave="duration-300 ease-out"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-6"
                  >
                    <DisclosurePanel className="dashboard-box-border dashboard-box-background relative flex flex-col gap-2 rounded-b-lg px-4 py-3 text-sm text-gray-600  dark:text-gray-300">
                      <p className="my-1">{step.subtitle}</p>
                      {step.ctaFunction && step.ctaText && (
                        <div className="flex justify-end ">
                          <Button
                            size="small"
                            width="auto"
                            type="tertiary"
                            onClick={() => {
                              step.ctaFunction();
                            }}
                          >
                            {step.ctaText}
                          </Button>
                        </div>
                      )}
                    </DisclosurePanel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
        <div className="relative z-10 mt-5 rounded-lg bg-slate-50 px-4 py-2 dark:bg-slate-800">
          {allStepsDone ? (
            <div className="flex">
              <CheckCircleIcon className="mr-2 mt-0.5 h-5 w-5 text-green-500" />
              <div>
                <div className="inline-flex text-sm">
                  Du hast alle Onboarding-Schritte abgeschlossen! 🚀
                </div>
                <div className="mt-2 flex gap-2">
                  <Button
                    size="small"
                    width="auto"
                    type="tertiary"
                    onClick={handleHide}
                  >
                    Ausblenden
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-sm">
              Noch{" "}
              {onboardingSteps.length -
                Object.keys(progress).filter((key) => progress[key])
                  .length}{" "}
              Schritt
              {onboardingSteps.length -
                Object.keys(progress).filter((key) => progress[key]).length >
              1
                ? "e"
                : ""}{" "}
              und du kannst vollends loslegen! 🚀
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingSteps;
