import clsx from "clsx";

const ButtonGroup = ({
  buttonsData,
  size = "medium",
  disabled,
}: {
  buttonsData: { title; onClick; active }[];
  size?: "small" | "medium";
  disabled?: boolean;
}) => {
  return (
    <span
      className={clsx(
        "inline-flex rounded-md shadow-none",
        disabled && "pointer-events-none cursor-not-allowed opacity-50"
      )}
    >
      {buttonsData.map((data, i) => {
        return (
          <button
            key={data.title}
            type="button"
            onClick={data.onClick}
            disabled={disabled}
            className={clsx(
              size === "small"
                ? "px-3 py-1.5 text-sm"
                : "px-4 py-2 text-xs font-semibold",
              "relative inline-flex items-center border bg-white focus:z-10  focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 dark:bg-transparent",
              i === 0 ? "rounded-l-md" : "",
              i === buttonsData.length - 1 ? "rounded-r-md" : "",
              data.active
                ? "btn-text-highlight-active dark:bg-orange-500/5"
                : "btn-text-default border-slate-300 hover:bg-slate-50 dark:border-slate-600 dark:hover:bg-slate-800 "
            )}
          >
            {data.title}
          </button>
        );
      })}
    </span>
  );
};

export default ButtonGroup;
